import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

const DetailsBlock = ({data, children}) => {
  const { details } = useStaticQuery(
    graphql`
    query {
      details: documentsYaml(type: { eq: "details" }) {
        abn
        name
        company
        address 
        phone
      }
    }
    `
  )
  return (
    <div className="border-2 border-gray-500 rounded p-3 text-xs">
      {details.name}
      {details.address.map((line) => (
        <div>{line}</div>
      ))}
    </div>
  )

}


export default DetailsBlock
