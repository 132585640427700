import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const PaymentBlock = ({ data, children }) => {
  const { details } = useStaticQuery(
    graphql`
      query {
        details: documentsYaml(type: { eq: "details" }) {
          abn
          name
          company
          address
          phone
          payment {
            payid
            bank {
              bsb
              acc
              name
            }
          }
        }
      }
    `
  )
  const { name, acc, bsb } = details.payment.bank
  const sectionClass = "gap-2 text-xs whitespace-nowrap px-4 grid grid-cols-2"
  return (
    <div className="flex flex-col border-2 rounded pb-4 my-5 w-3/4">
      <div className="w-full text-xs text-center mb-2 border-b-2 uppercase py-1" > pay to </div>
      <div className={sectionClass}>
        <div>name:</div>
        <div>{name}</div>
      </div>
      <div className={sectionClass}>
        <div>bsb:</div>
        <div> {bsb}</div>
      </div>
      <div className={sectionClass}>
        <div>account:</div>
        <div> {acc}</div>
      </div>
    </div>
  )
}

export default PaymentBlock
